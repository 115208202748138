import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import {useState} from "react";
import {ChevronUpIcon, ChevronDownIcon} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {User} from "../../../pages/Settings/interfaces"

const formatDate = (isoDate: string | null): string => {
    if (!isoDate) {
        return "N/A";
    }

    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("es-ES", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    }).format(date);
};

interface UsersTableProps {
    users: User[];
    searchTerm: string;
}

const UsersTable = ({users, searchTerm}:UsersTableProps) => {

    const [sortConfig, setSortConfig] = useState<{ key: string | null; direction: "asc" | "desc" | null }>({
        key: null,
        direction: null,
    });
    const navigate = useNavigate();

    const handleSort = (key: string) => {
        setSortConfig((prevState) => {
            if (prevState.key === key) {
                return {
                    key,
                    direction: prevState.direction === "asc" ? "desc" : "asc",
                };
            }
            return {key, direction: "asc"};
        });
    };

    const sortedUsers = [...users].sort((a, b) => {
        if (sortConfig.key) {
            const key = sortConfig.key as keyof User;
            const valueA = a[key];
            const valueB = b[key];

            if (typeof valueA === "string" && typeof valueB === "string") {
                return sortConfig.direction === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }

            if (typeof valueA === "number" && typeof valueB === "number") {
                return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
            }
        }
        return 0;
    });

    const filteredUsers = sortedUsers.filter((user) =>
        Object.values(user)
            .join(" ")
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    const handleRowClick = (user:User) => {
        navigate(`/users/edit/${user.id}`)
    };

    return (
        <Box overflowX="auto" border="1px solid #E2E8F0" borderRadius="md" bg="white" shadow="sm">
            <Table variant="simple">
                <Thead bg="gray.100">
                    <Tr>
                        <Th onClick={() => handleSort("name")} cursor="pointer">
                            Usuario
                            <ChevronUpIcon ml={2}
                                color={sortConfig.key === "name" && sortConfig.direction === "asc" ? "blue.500" : "gray.400"}/>
                            <ChevronDownIcon ml={1}
                                color={sortConfig.key === "name" && sortConfig.direction === "desc" ? "blue.500" : "gray.400"}/>
                        </Th>
                        <Th onClick={() => handleSort("email")} cursor="pointer">
                            Email
                            <ChevronUpIcon ml={2}
                                color={sortConfig.key === "email" && sortConfig.direction === "asc" ? "blue.500" : "gray.400"}/>
                            <ChevronDownIcon ml={1}
                                color={sortConfig.key === "email" && sortConfig.direction === "desc" ? "blue.500" : "gray.400"}/>
                        </Th>
                        <Th onClick={() => handleSort("role")} cursor="pointer">
                            Rol
                            <ChevronUpIcon ml={2}
                                color={sortConfig.key === "role" && sortConfig.direction === "asc" ? "blue.500" : "gray.400"}/>
                            <ChevronDownIcon ml={1}
                                color={sortConfig.key === "role" && sortConfig.direction === "desc" ? "blue.500" : "gray.400"}/>
                        </Th>
                        <Th>
                            Último acceso
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredUsers.map((user, index) => (
                        <Tr key={user.id} bg={index % 2 === 0 ? "gray.50" : "white"}
                            onClick={() => handleRowClick(user)} cursor="pointer">
                            <Td>{user.name}</Td>
                            <Td>{user.email}</Td>
                            <Td style={{textTransform: "capitalize"}}>{user.role}</Td>
                            <td>{formatDate(user.last_login)}</td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default UsersTable;
