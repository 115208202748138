import {useState, useEffect} from "react";
import {Flex, Text} from "@chakra-ui/react";
import {Button} from "@canaia/ui-kit";
import {useTranslation} from "react-i18next";
import {useGetTokenOrganization} from "../../../hooks/useGetAccessTokenOrganization.tsx";
import {authStore} from "../../../store/authStore.tsx";
import {useLogto} from "@logto/react";
import {fetchPlan} from "../../../api/Calls/products.tsx";
import {fetchManagment, fetchSeats} from "../../../api/Calls/managment.tsx";


interface ProductSeatsProps {
    product: string
}

interface PlanData {
    period_end: string,
    period_start: string,
    plan: string,
    product: string
}

interface AvailableSeats {
    total: number,
    available: number,
    used: number
}

const ProductSeats = ({product}: ProductSeatsProps) => {
    const {t} = useTranslation();
    const {fetchUpdatedToken} = useGetTokenOrganization();
    const organizationId = authStore((state) => state.organizationId);
    const {signOut} = useLogto();
    const [managementUrl, setManagementUrl] = useState<string | null>(null);
    const [seatsData, setSeatsData] = useState<AvailableSeats | null>(null);
    const [planData, setPlanData] = useState<PlanData | null >(null);

    useEffect(() => {
        const loadData = async () => {
            try {

                const token = await fetchUpdatedToken();
                if (!token) throw new Error("No se pudo obtener el token.");
                if (!organizationId) throw new Error("Organization ID is missing.");

                if (product === "assist") {
                    const plan = await fetchPlan(token, product, signOut)
                    setPlanData(plan)
                } else if (product === "elevate") {
                    const seats = await fetchSeats(token, organizationId, product, signOut);
                    setSeatsData(seats);
                }

                const url = await fetchManagment(token, "assist", signOut);
                setManagementUrl(url.url);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        loadData();
    }, [fetchUpdatedToken, organizationId, planData?.plan, product, signOut]);

    const handleRedirect = () => {
        if (managementUrl) {
            window.open(managementUrl, "_blank");
        } else {
            setManagementUrl(null)
        }
    };

    return (
        <>
            <Flex mb={2} mr={4} mt={2} alignItems="center" justify="space-between" width="auto">
                <Text fontSize="x-large" color="black.500" fontWeight={"bold"} textAlign={"right"} fontFamily={"Lato-Bold"}>
                    {product === "elevate"
                        ? (seatsData?.available === 0
                            ? t("No cuentas con lugares disponibles")
                            : `${seatsData?.available ?? 0} / ${seatsData?.total ?? 0} ${t("licencias disponibles")}`)
                        : planData?.plan ?? t("No cuentas con lugares disponibles")}
                </Text>
                <Button
                    ml={5}
                    fontSize="lg"
                    size="lg"
                    fontFamily={"Sansation-Bold"}
                    text={ product === "elevate" ? t("Comprar licencia") : t("Cambiar plan")}
                    color={"primary.400"}
                    onClick={handleRedirect}
                    isDisabled={!managementUrl}
                ></Button>
            </Flex>
        </>
    )
};

export default ProductSeats;
