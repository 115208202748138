
import {Button, ModalCustom, Text, CardCustom as Card, useCustomToast} from "@canaia/ui-kit";
import {useTranslation} from "react-i18next";
import {Checkbox, HStack, VStack, Spinner, Box, Flex} from "@chakra-ui/react";
import {useCallback, useEffect, useRef, useState} from "react";
import {getMembers} from "../../../api/Calls/organizations.tsx";
import {useGetTokenOrganization} from "../../../hooks/useGetAccessTokenOrganization.tsx";
import {useLogto} from "@logto/react";
import {addProductUser, deleteProductUserRol} from "../../../api/Calls/products.tsx";
import ElevateIcon from "../../../icons/productsIcons/ElevateIcon.tsx";
import AssistIcon from "../../../icons/productsIcons/AssistIcon.tsx";
import {User} from "../interfaces";

interface ModalAddUsersProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    organizationId: string;
    users: User[];
    product: string;
    handleUsersUpdated: (updatedUsers: User[], product: string) => void;
}

const ModalAddUsers = (
    {
        isModalOpen, setIsModalOpen, organizationId, users, product, handleUsersUpdated
    }: ModalAddUsersProps) => {
    const { t } = useTranslation();
    const { fetchUpdatedToken } = useGetTokenOrganization();
    const { signOut } = useLogto();

    const [orgUsers, setOrgUsers] = useState<User[]>([]);
    const [productUsers, setProductUsers] = useState<User[]>([]);
    const [selectedOrgUsers, setSelectedOrgUsers] = useState<User[]>([]);
    const [selectedProductUsers, setSelectedProductUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [isAddLoading, setIsAddLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [hasFetchedMembers, setHasFetchedMembers] = useState(false);
    const showToast = useCustomToast();
    const showToastRef = useRef(showToast);

    useEffect(() => {
        showToastRef.current = showToast;
    }, [showToast]);

    useEffect(() => {
        if (isModalOpen) {
            setProductUsers(users);
        }
    }, [isModalOpen, users]);

    useEffect(() => {
        if (isModalOpen) {
            setSelectedOrgUsers([]);
            setSelectedProductUsers([]);
            setHasFetchedMembers(false);
        }
    }, [isModalOpen]);

    const fetchMembers = useCallback(async () => {
        try {
            if (hasFetchedMembers) return;

            setLoading(true);
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");
            if (!organizationId) throw new Error("Organization ID is missing.");
            const membersData = await getMembers(token, signOut, organizationId);
            const filteredOrgUsers = membersData.items.filter((member: User) =>
                !users.some((user: { id: string; }) => user.id === member.id)
            );
            setOrgUsers(filteredOrgUsers);
            setHasFetchedMembers(true);
        } catch (error) {
            console.error("Error fetching members:", error);
        } finally {
            setLoading(false);
        }
    }, [fetchUpdatedToken, organizationId, signOut, users, hasFetchedMembers]);

    useEffect(() => {
        if (isModalOpen && product && !hasFetchedMembers) {
            fetchMembers();
        }
    }, [fetchMembers, isModalOpen, product, hasFetchedMembers]);

    const handleTransferToProduct = async () => {
        if (selectedOrgUsers.length === 0) return;
        setIsAddLoading(true);

        const usersToTransfer = selectedOrgUsers.filter(user =>
            !productUsers.some(u => u.id === user.id)
        );

        if (usersToTransfer.length === 0) {
            showToast({
                type: "warning",
                title: "¡Cuidado!",
                description: t("No se seleccionó ningún usuario"),
                duration: 5000,
                position: "bottom-center",
            });
            setIsAddLoading(false);
            return;
        }

        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");

            const assignedRole = product === "elevate" ? "viewer" : "member";

            await Promise.allSettled(
                usersToTransfer.map(user =>
                    addProductUser(token, signOut, organizationId, product, user.id, { role: assignedRole })
                )
            );

            const updatedUsers: User[] = usersToTransfer.map(user => ({ ...user, role: assignedRole }));

            setProductUsers(prev => [...prev, ...updatedUsers]);
            setOrgUsers(prev => prev.filter(user => !usersToTransfer.some(tu => tu.id === user.id)));
            setSelectedOrgUsers([]);

            showToast({
                type: "success",
                title: "¡Éxito!",
                description: t("Usuarios asignados correctamente."),
                duration: 5000,
                position: "bottom-center",
            });

            handleUsersUpdated([...productUsers, ...updatedUsers], product);
        } catch (error) {
            console.error("Error al actualizar usuarios:", error);
            showToast({
                type: "error",
                title: "¡Error!",
                description: t("Hubo un problema al agregar usuarios"),
                duration: 5000,
                position: "bottom-center",
            });
        } finally {
            setIsAddLoading(false);
        }
    };


    const handleTransferToOrg = async () => {
        if (selectedProductUsers.length === 0) return;
        setIsDeleteLoading(true);

        const usersToTransfer = selectedProductUsers.filter(user =>
            !orgUsers.some(u => u.id === user.id)
        );

        if (usersToTransfer.length === 0) {
            showToast({
                type: "warning",
                title: "¡Cuidado!",
                description: t("No se seleccionó ningún usuario"),
                duration: 5000,
                position: "bottom-center",
            });
            setIsDeleteLoading(false);
            return;
        }

        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");

            await Promise.allSettled( // `allSettled` permite que los errores individuales no interrumpan el flujo
                usersToTransfer.map(user =>
                    deleteProductUserRol(token, signOut, organizationId, product, user.id)
                )
            );

            setOrgUsers(prev => [...prev, ...usersToTransfer]);
            setProductUsers(prev => prev.filter(user => !usersToTransfer.includes(user)));
            setSelectedProductUsers([]);

            showToast({
                type: "success",
                title: "¡Éxito!",
                description: t("Usuarios eliminados correctamente."),
                duration: 5000,
                position: "bottom-center",
            });

            handleUsersUpdated(productUsers.filter(user => !usersToTransfer.includes(user)), product);
        } catch (error) {
            console.error("Error al eliminar usuarios:", error);
            showToast({
                type: "error",
                title: "¡Error!",
                description: t("Hubo un problema al eliminar usuarios"),
                duration: 5000,
                position: "bottom-center",
            });
        } finally {
            setIsDeleteLoading(false);
        }
    };


    const toggleSelection = (user:User, selectedList: User[], setSelectedList: React.Dispatch<React.SetStateAction<User[]>>) => {
        setSelectedList(
            selectedList.some(u => u.id === user.id)
                ? selectedList.filter(u => u.id !== user.id)
                : [...selectedList, user]
        );
    };

    const modalArgs = {
        backgroundColorModal: "white.100",
        title: t("Asignación de Usuarios"),
        titleAlign: "left",
        fontSizeTitle: "2xl",
        disableOverlay: false,
        buttonAcceptVisible: false,
        buttonCancelVisible: false,
        maxWidth: '750px',
        isCenteredModal: true,
    };

    const renderIcon = () => {

        const iconSize = 16;

        if (product === "elevate") {
            return <ElevateIcon width={iconSize} height={iconSize} />;
        }

        if (product === "assist") {
            return <AssistIcon width={iconSize} height={iconSize} />;
        }
        return null;
    };

    return (
        <ModalCustom {...modalArgs} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            {loading ? (
                <Box textAlign="center" py={4}>
                    <Spinner size="xl" color="primary.500" />
                </Box>
            ) : (
                <>
                    <Text textAlign="left" fontSize={"lg"} text={t("Permite a usuarios de la organización acceder al producto")} />
                    <HStack justifyContent="space-between" mt={4} gap={6} height="100%" alignItems="stretch">
                        <Card maxWidth={"300px"} padding={0} borderRadius="xl" boxShadow="md" bg="gray.50" flex="1">
                            <Box
                                background={"primary.400"}
                                style={{paddingBlockEnd: '20px', paddingBlockStart: '20px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
                                fontFamily="Sansation-Bold"
                            >
                                <Text color={"white.100"} fontSize={"1.25rem"} text={t("Usuarios de la organización")}></Text>
                            </Box>
                            <VStack px={5} py={5} spacing={3} textAlign="left" flex="1" alignItems="stretch">
                                {orgUsers.map(user => (
                                    <Checkbox
                                        key={user.id}
                                        isChecked={selectedOrgUsers.some(u => u.id === user.id)}
                                        onChange={() => toggleSelection(user, selectedOrgUsers, setSelectedOrgUsers)}
                                    >
                                        {user.name} {user.last_name}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </Card>
                        <VStack justifyContent="center" alignItems="center" flexShrink={0} height="full" alignSelf={"center"}>
                            <Button
                                loading={isAddLoading}
                                onClick={handleTransferToProduct}
                                disabled={selectedOrgUsers.length === 0 || isDeleteLoading || isAddLoading}
                                size="sm"
                                fontWeight="bold"
                                color="primary.400"
                                icon={"arrowRight"}
                                buttonIcon={true}
                                iconSize={"28px"}
                                py={"20px"}
                                px={"6px"}
                            />
                            <Button
                                loading={isDeleteLoading}
                                onClick={handleTransferToOrg}
                                disabled={selectedProductUsers.length === 0 || isAddLoading || isDeleteLoading}
                                size="sm"
                                color="primary.400"
                                icon={"arrowLeft"}
                                buttonIcon={true}
                                iconSize={"28px"}
                                py={"20px"}
                                px={"6px"}
                            />
                        </VStack>
                        <Card width="40%" maxWidth={"300px"} padding={0} borderRadius="xl" boxShadow="md" bg="red.50" flex={"1"}>
                            <Box
                                background={`${product}.500`}
                                style={{paddingBlockEnd: '20px', paddingBlockStart: '20px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
                                fontFamily="Sansation-Bold"
                            >
                                <Flex alignItems="center" justifyContent={"center"} gap={2}>
                                    <Box
                                        background={`${product}.700`}
                                        padding="5px"
                                        borderRadius="100%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {renderIcon()}
                                    </Box>
                                    <Text
                                        style={{textTransform: "capitalize"}}
                                        color={"white.100"}
                                        fontSize={"1.25rem"}
                                        text={product}>
                                    </Text>
                                </Flex>
                            </Box>
                            <VStack px={5} py={5} spacing={3} textAlign="left" flex="1" alignItems="stretch">
                                {productUsers.map(user => (
                                    <Checkbox
                                        key={user.id}
                                        isChecked={selectedProductUsers.some(u => u.id === user.id)}
                                        onChange={() => toggleSelection(user, selectedProductUsers, setSelectedProductUsers)}
                                    >
                                        {user.name} {user.last_name}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </Card>
                    </HStack>
                </>
            )}
        </ModalCustom>
    );
};


export default ModalAddUsers;