import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import colors from "./colors";

const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const theme = extendTheme({
    config,
    colors,
    styles: {
        global: {
            body: {
                fontFamily: "Lato-Regular, sans-serif",
                color: "#000",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "normal",
            },
            "*": {
                boxSizing: "border-box",
            },
        },
    },
});

export default theme;
