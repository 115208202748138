import React from 'react';

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
}

const AssistIcon: React.FC<IconProps> = ({ width = 24, height = 24}) => (
    <svg width={width} height={height} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.99508 2.98391C7.55755 3.09018 8.09988 2.72191 8.20797 2.15944C8.31424 1.59696 7.94597 1.05464 7.3835 0.946546C6.82102 0.84028 6.2787 1.20855 6.17061 1.77102C6.06434 2.33349 6.43261 2.87582 6.99508 2.98391Z"
            fill="white"/>
        <path
            d="M7.79376 6.11495C9.99786 6.40443 11.7054 8.29522 11.7054 10.5781C11.7054 13.0607 9.68639 15.0797 7.20197 15.0797C4.71756 15.0797 2.69851 13.0607 2.69851 10.5781C2.69851 8.31904 4.37128 6.4429 6.54423 6.12411V4.63456C4.10012 4.90205 2.09573 6.64811 1.44531 8.95663C1.66884 9.05374 1.86671 9.23879 2.02244 9.49895C2.24047 9.86172 2.36506 10.3454 2.37239 10.8621C2.38521 11.714 2.0884 12.4249 1.63219 12.7565C2.5043 14.9808 4.67175 16.5601 7.20197 16.5601C9.73219 16.5601 11.8575 15.0101 12.7461 12.8207C12.5684 12.7144 12.4072 12.5532 12.2771 12.3351C12.059 11.9724 11.9345 11.4887 11.9271 10.972C11.9198 10.4553 12.0297 9.96249 12.2386 9.5814C12.4218 9.24795 12.6692 9.02259 12.9513 8.92732C12.2844 6.61329 10.2562 4.87091 7.79376 4.62723V6.11678V6.11495Z"
            fill="white"/>
        <path
            d="M1.5006 9.70796C1.44563 9.61635 1.38517 9.5394 1.32288 9.47894C1.22211 9.38 1.11584 9.32687 1.0224 9.33053C1.01508 9.33053 1.00958 9.33053 1.00225 9.33053C0.855677 9.34885 0.685286 9.50459 0.555202 9.7446C0.379314 10.0689 0.287706 10.494 0.293202 10.9465C0.300531 11.399 0.404964 11.8168 0.588181 12.1227C0.732922 12.3609 0.916138 12.5057 1.06638 12.5002C1.1873 12.4947 1.32654 12.3939 1.4493 12.2162C1.64351 11.9395 1.80291 11.476 1.79375 10.8842C1.78642 10.4317 1.68198 10.0139 1.49877 9.70796H1.5006Z"
            fill="white"/>
        <path
            d="M13.7115 9.70793C13.5668 9.46975 13.3836 9.32501 13.2333 9.3305C13.226 9.3305 13.2205 9.3305 13.2132 9.3305C13.1656 9.336 13.1143 9.35799 13.0648 9.3928C12.9603 9.46242 12.8541 9.58517 12.7661 9.74641C12.5903 10.067 12.4986 10.4921 12.5041 10.9446C12.5115 11.3972 12.6159 11.8149 12.7991 12.1209C12.8394 12.1887 12.8834 12.2473 12.9292 12.2986C13.0446 12.4287 13.1692 12.5038 13.2773 12.4983C13.5869 12.4818 14.0193 11.8497 14.0065 10.8823C13.9992 10.4298 13.8948 10.0121 13.7115 9.7061V9.70793Z"
            fill="white"/>
        <path
            d="M4.98802 8.98782C4.56112 8.9695 4.21484 9.58694 4.21484 10.3674C4.21484 11.1479 4.56112 11.7965 4.98985 11.8149C5.41858 11.8332 5.76302 11.2157 5.76302 10.4352C5.76302 9.65473 5.41674 9.00615 4.98802 8.98782ZM5.25551 11.6023C5.06863 11.7764 4.79747 11.7232 4.64907 11.4851C4.50066 11.2469 4.52998 10.9116 4.71686 10.7375C4.90374 10.5635 5.1749 10.6166 5.3233 10.8548C5.47171 11.093 5.44239 11.4283 5.25551 11.6023Z"
            fill="white"/>
        <path
            d="M8.85338 8.89435C8.42649 8.87603 8.08021 9.49347 8.08204 10.274C8.08204 11.0545 8.43015 11.7012 8.85704 11.7195C9.28394 11.7379 9.63022 11.1204 9.62839 10.3399C9.62839 9.55942 9.28027 8.91267 8.85338 8.89435ZM9.10622 11.5437C8.91751 11.7141 8.64635 11.6591 8.5016 11.4172C8.35686 11.1772 8.39167 10.8419 8.58039 10.6715C8.7691 10.5012 9.04026 10.5561 9.185 10.798C9.32974 11.0398 9.29493 11.3733 9.10622 11.5437Z"
            fill="white"/>
        <path
            d="M7.37684 6.08027V3.49692H6.96094V6.08393C7.04155 6.08027 7.12217 6.07661 7.20278 6.07661C7.26141 6.07661 7.32004 6.07844 7.37684 6.08027Z"
            fill="white"/>
    </svg>


);

export default AssistIcon;
