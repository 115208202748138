import {createApiClient} from '../ApiClients';

export const fetchOrganizations = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void
) => {
    try {
        const apiClient = createApiClient(token, signOut, showToast);
        const response = await apiClient.get(`/organizations`);
        return response.data;
    } catch (error: unknown) {
        showToast('Error fetching organizations');
        throw error;
    }
};
