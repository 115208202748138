import React, {useEffect, useState} from "react";
import {Box, Flex, Spinner} from "@chakra-ui/react";
import ProfileForm from "../../ui/Components/Form";
import {authStore} from "../../store/authStore";
import {useLogto} from "@logto/react";
import {useTranslation} from "react-i18next";
import {useGetTokenOrganization} from "../../hooks/useGetAccessTokenOrganization";
import {useCustomToast} from '@canaia/ui-kit';
import '@canaia/ui-kit/dist/style.css';
import {useParams, useNavigate} from "react-router-dom";
import {
    deleteMember,
    getMember,
    getMembers,
    updateMember,
    updateMemberPassword, updateMemberRol
} from "../../api/Calls/organizations.tsx";
import {SubmitHandler, useForm} from "react-hook-form";
import {ProfileFormData} from "../../ui/Components/Form/interfaces.tsx";
import axios from "axios";

const UsersForm: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const {t} = useTranslation();
    const showToast = useCustomToast();
    const {fetchUpdatedToken} = useGetTokenOrganization();
    const {signOut} = useLogto();
    const organizationId = authStore((state) => state.organizationId);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const {control, handleSubmit, formState: {errors, isSubmitting}, reset, watch} = useForm({
        defaultValues: {
            email: "",
            name: "",
            last_name: "",
            address: "",
            city: "",
            country: "",
            phone: "",
            postal_code: "",
            language: "es",
            old_password: "",
            new_password: "",
            repeat_password: "",
            role: ""
        }
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = await fetchUpdatedToken();
                if (!token) throw new Error("No se pudo obtener el token.");
                if (!id) throw new Error("Error al obtener el id de usuario");
                let user = await getMember(token, signOut, organizationId, id);
                user = normalizeUserData(user);
                setRole(user.role);
                reset(user);
            } catch (error) {
                console.error("Error cargando datos del usuario:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [id, fetchUpdatedToken, signOut, organizationId, reset]);

    const handleUpdateUser: SubmitHandler<ProfileFormData> = async (data) => {

        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("No se pudo obtener el token.");
            if (!id) throw new Error("Error al obtener el id de usuario");

            const { role, ...updatedData } = { ...data, language: "es" };

            await Promise.all([
                updateMember(token, organizationId, id, updatedData, signOut),
                role && role !== "owner"
                    ? updateMemberRol(token, signOut, organizationId, id, { role })
                    : Promise.resolve()
            ]);

            showToast({
                type: "success",
                title: "¡Éxito!",
                description: t("El perfil del usuario se ha actualizado correctamente."),
                duration: 5000,
                position: "bottom-center",
            });
        } catch (error) {
            console.error("Error actualizando usuario:", error);
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    showToast({
                        type: "warning",
                        title: "Error de Validación",
                        description: "Hubo un error con los datos ingresados. Por favor, verifica e inténtalo de nuevo.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else if (error.response?.status === 401 || error.response?.status === 403) {
                    showToast({
                        type: "error",
                        title: "Error de Autenticación",
                        description: "Tu sesión ha expirado. Por favor, inicia sesión nuevamente.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else {
                    showToast({
                        type: "error",
                        title: "Error",
                        description: error.response?.data.detail,
                        duration: 5000,
                        position: "bottom-center",
                    });
                }
            } else {
                showToast({
                    type: "error",
                    title: "Error",
                    description: "Hubo un problema al procesar tu solicitud. Inténtalo más tarde.",
                    duration: 5000,
                    position: "bottom-center",
                });
            }
        }

    };

    const handleUpdatePassword: SubmitHandler<ProfileFormData> = async (data) => {

        try {
            const {new_password, repeat_password} = data;
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("No se pudo obtener el token.");
            if (!id) throw new Error("Error al obtener el id de usuario");

            if (new_password !== repeat_password) {
                showToast({
                    type: "warning",
                    title: t("Cuidado"),
                    description: t("Las contraseñas no coinciden"),
                    duration: 5000,
                    position: "bottom-center",
                });
                return;
            }

            await updateMemberPassword(token, signOut, organizationId, id, {new_password})
            showToast({
                type: "success",
                title: "¡Éxito!",
                description: t("La contraseña se ha actualizado correctamente."),
                duration: 5000,
                position: "bottom-center",
            });
            setIsModalOpen(false);

        } catch (error) {
            console.error("Error actualizando usuario:", error);

            if (axios.isAxiosError(error)) {
                const {response} = error;
                if (response?.status === 401 || response?.status === 403) {

                    showToast({
                        type: "error",
                        title: t("Sesión Expirada"),
                        description: t("Tu sesión ha expirado. Por favor, inicia sesión nuevamente."),
                        duration: 5000,
                        position: "bottom-center",
                    });
                    await signOut();
                } else {

                    showToast({
                        type: "error",
                        title: t("Error del Servidor"),
                        description: response,
                        duration: 5000,
                        position: "bottom-center",
                    });
                }
            } else {

                showToast({
                    type: "error",
                    title: t("Error"),
                    description: "Hubo un problema al procesar tu solicitud. Inténtalo más tarde.",
                    duration: 5000,
                    position: "bottom-center",
                });
            }
        }
    }

    const handleDeleteUser = async () => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("No se pudo obtener el token.");
            if (!id) throw new Error("Error al obtener el id de usuario");

            await deleteMember(token, signOut, organizationId, id );

            showToast({
                type: "success",
                title: "Usuario eliminado",
                description: "El usuario ha sido eliminado correctamente.",
                duration: 5000,
                position: "bottom-center",
            });

            navigate("/users");
            await getMembers(token, signOut, organizationId);

        } catch (error) {
            console.error("Error al eliminar el usuario:", error);

            if (axios.isAxiosError(error)) {
                const {response} = error;
                if (response?.status === 401 || response?.status === 403) {

                    showToast({
                        type: "error",
                        title: t("Sesión Expirada"),
                        description: t("Tu sesión ha expirado. Por favor, inicia sesión nuevamente."),
                        duration: 5000,
                        position: "bottom-center",
                    });
                    await signOut();
                } else {

                    showToast({
                        type: "error",
                        title: t("Error del Servidor al eliminar el usuario"),
                        description: response,
                        duration: 5000,
                        position: "bottom-center",
                    });
                }
            } else {

                showToast({
                    type: "error",
                    title: t("Error"),
                    description: "Hubo un problema al procesar tu solicitud. Inténtalo más tarde.",
                    duration: 5000,
                    position: "bottom-center",
                });
            }
        }
    }

    const handleCancel = () => {
        reset();
    };

    const normalizeUserData = (data: ProfileFormData): ProfileFormData => {

        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value ?? ""])
        ) as ProfileFormData;
    };

    if (loading) {
        return <>
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" color="teal.500"/>
            </Flex></>
    }


    return (
        <Box maxW="900px" mx={"auto"}>
            <ProfileForm
                onSubmit={handleSubmit(handleUpdateUser)}
                onPasswordSubmit={handleSubmit(handleUpdatePassword)}
                onDeleteSubmit={handleDeleteUser}
                control={control}
                errors={errors}
                handleCancel={handleCancel}
                watch={watch}
                reset={reset}
                isSubmitting={isSubmitting}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isDeleteModalOpen={isDeleteModalOpen}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                userType={"user"}
                role={role}
            />
        </Box>
    );
};

export default UsersForm;
