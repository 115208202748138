import {createApiClient, createOrganizationsApiClient} from '../ApiClients';

export const fetchManagment = async (token: string, product: string, signOut: () => Promise<void>, showToast?: (message: string) => void) => {
    try {
        const apiClient = createApiClient(token, signOut, showToast);  // Crear cliente con el interceptor de 401
        const response = await apiClient.get(`/subscriptions/${product}/portal`);
        return response.data;
    } catch (error) {
        console.error("Error fetching management data:", error);
        throw error;
    }
};

export const fetchSeats = async (token: string, organization_id:string, product:string,  signOut: () => Promise<void> ) => {
    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.get(`${organization_id}/product/${product}/seats`);
        return response.data;
    } catch (error) {
        console.error("Error fetching management data:", error);
        throw error;
    }
}
