import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Link,
} from "@chakra-ui/react";
import {Button} from "@canaia/ui-kit";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import {User} from "../../../pages/PermissionsAndRoles/interfaces"

const formatDate = (isoDate: string | null): string => {
    if (!isoDate) return t("Sin datos");
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("es-ES", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    }).format(date);
};

interface PermissionsAndRolesTableProps {
    users: User[];
    searchTerm: string;
    product: string;
    selectedUsers: Set<string>;
    setSelectedUsers: React.Dispatch<React.SetStateAction<Set<string>>>;
    openModal: (user: User) => void;
}

const PermissionsAndRolesTable = ({
    users,
    searchTerm,
    product,
    selectedUsers,
    setSelectedUsers,
    openModal
}: PermissionsAndRolesTableProps) => {
    const [sortConfig, setSortConfig] = useState<{ key: string | null; direction: "asc" | "desc" | null }>({
        key: null,
        direction: null,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10;
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedUsers(new Set());
    }, [product, setSelectedUsers]);

    const handleSort = (key: string) => {
        setSortConfig((prevState) => {
            if (prevState.key === key) {
                return { key, direction: prevState.direction === "asc" ? "desc" : "asc" };
            }
            return { key, direction: "asc" };
        });
    };

    const sortedUsers = [...users].sort((a, b) => {
        if (sortConfig.key) {
            const key = sortConfig.key as keyof User; 
            const valueA = a[key];
            const valueB = b[key];

            if (typeof valueA === "string" && typeof valueB === "string") {
                return sortConfig.direction === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }

            if (typeof valueA === "number" && typeof valueB === "number") {
                return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
            }
        }
        return 0;
    });

    const filteredUsers = sortedUsers.filter((user) =>
        Object.values(user).join(" ").toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleUserSelection = (userId: string) => {
        setSelectedUsers(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(userId)) {
                newSelected.delete(userId);
            } else {
                newSelected.add(userId);
            }
            return newSelected;
        });
    };

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const paginatedUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    return (
        <Box overflowX="auto" border="1px solid #E2E8F0" borderRadius="md" bg="white" shadow="sm" p="24px">
            <Table variant='simple' size='lg' fontSize={"md"}>
                <Thead borderBottom="2px solid #E2E8F0">
                    <Tr>
                        <Th onClick={() => handleSort("name")}
                            cursor="pointer"
                            fontFamily={"Sansation-Bold"} textTransform={"capitalize"} fontSize={"md"} color={"black.500"}>
                            Usuario
                            <ChevronDownIcon fontSize={"xl"}
                                color={sortConfig.key === "name" && sortConfig.direction === "desc" ? "blue.500" : "gray.400"}/>
                        </Th>
                        <Th onClick={() => handleSort("email")} cursor="pointer" fontFamily={"Sansation-Bold"} textTransform={"capitalize"} fontSize={"md"} color={"black.500"}>
                            Email
                            <ChevronDownIcon fontSize={"xl"}
                                color={sortConfig.key === "email" && sortConfig.direction === "desc" ? "blue.500" : "gray.400"}/>
                        </Th>
                        <Th onClick={() => handleSort("role")} cursor="pointer" fontFamily={"Sansation-Bold"} textTransform={"capitalize"} fontSize={"md"} color={"black.500"}>
                            Rol
                            <ChevronDownIcon fontSize={"xl"}
                                color={sortConfig.key === "role" && sortConfig.direction === "desc" ? "blue.500" : "gray.400"}/>
                        </Th>
                        <Th fontFamily={"Sansation-Bold"} textTransform={"capitalize"} fontSize={"md"} color={"black.500"}>Último acceso</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {paginatedUsers.map((user) => (
                        <Tr key={user.id} bg={"white.100"}>
                            <Td display="flex" alignItems="center" border="none" py={3}>
                                <input
                                    type="checkbox"
                                    checked={selectedUsers.has(user.id)}
                                    onChange={() => handleUserSelection(user.id)}
                                    style={{ marginRight: "8px" }}
                                />
                                <Link
                                    color="blue.500"
                                    textDecoration="underline"
                                    fontWeight={"bold"}
                                    onClick={() => navigate(`/users/edit/${user.id}`)}
                                    _hover={{ color: "blue.700" }}
                                >
                                    {user.name}
                                </Link>
                            </Td>
                            <Td border="none" py={3}>{user.email}</Td>
                            <Td
                                style={{textTransform: "capitalize", cursor: "pointer", textDecoration: "underline"}}
                                onClick={() => openModal(user)}
                                border="none"
                                py={3}
                            >
                                {user.role}
                            </Td>
                            <Td color={"black.300"} border="none" py={3}>{formatDate(user.last_login)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Box display="flex" justifyContent="flex-end" mt={4} alignItems="center">
                {totalPages > 1 && (
                    <>
                        <Button
                            text={"<"}
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            type={"outline"}
                            disabled={currentPage === 1}
                            color={"primary.500"}
                            style={{
                                color: "#222222",
                                borderColor: "transparent",
                            }}
                        ></Button>
                        {[...Array(totalPages)].map((_, index) => (
                            <Button
                                key={index}
                                onClick={() => setCurrentPage(index + 1)}
                                text={index + 1}
                                type={"outline"}
                                color={currentPage === index + 1 ? "primary.500" : "white.100"}
                                mx={1}
                                style={{
                                    color: "#222222",
                                }}
                            />
                        ))}
                        <Button
                            text={">"}
                            onClick={() => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))}
                            disabled={currentPage === totalPages}
                            type={"outline"}
                            color={"primary.500"}
                            style={{
                                color: "#222222",
                                borderColor: "transparent",
                            }}
                        ></Button>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default PermissionsAndRolesTable;
