import {createApiClient, createOrganizationsApiClient} from '../ApiClients';

interface updatedRol {
    role: string
}

type ApiResponse = {
    status: number;
    data: { name: string, plan: string | null | boolean };
};

export const fetchPlans = async (token: string, signOut: () => Promise<void> ) => {
    const apiClient = createApiClient(token, signOut);

    try {
        const responses: ApiResponse[] = await Promise.all([
            apiClient.get(`/subscriptions/play`).catch(error => {
                if (error.response?.status === 404) {
                    return { data: { name: 'Play', plan: false }, status: 404 };
                }
                throw error;
            }),
            apiClient.get(`/subscriptions/assist`).catch(error => {
                if (error.response?.status === 404) {
                    return { data: { name: 'Assist', plan: false }, status: 404 };
                }
                throw error;
            })
        ]);

        const playData = {
            ...responses[0].data,
            name: 'Play',
            description: "playDescription"
        };

        const assistData = {
            ...responses[1].data,
            name: 'Assist',
            description: "assistDescription"
        };

        return [playData, assistData];
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const fetchPlan = async (token: string, product:string, signOut: () => Promise<void> ) => {
    try {
        const apiClient = createApiClient(token, signOut);
        const response = await apiClient.get(`subscriptions/${product}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching the subscription plan", error);
        throw error;
    }
}

export const fetchProductUsers = async (token: string, signOut: () => Promise<void>, organization_id:string, product:string) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.get(`${organization_id}/product/${product}/users`);
        return response.data;
    } catch (error) {
        console.error("Error deleting user:", error);
        throw error;
    }

}

export const addProductUser = async (token:string, signOut: () => Promise<void>, organization_id:string, product:string, user_id:string, data: updatedRol ) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.patch(`${organization_id}/product/${product}/users/${user_id}`, data);
        return response.data;
    } catch(error) {
        console.error("Error updating user:", error);
        throw error;
    }

}

export const updateProductUserRol = async (token:string, signOut: () => Promise<void>, organization_id:string, product:string, user_id:string, data: updatedRol ) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.patch(`${organization_id}/product/${product}/users/${user_id}`, data);
        return response.data;
    } catch(error) {
        console.error("Error updating user:", error);
        throw error;
    }

}

export const deleteProductUserRol = async (token:string, signOut: () => Promise<void>, organization_id:string, product:string, user_id:string ) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.delete(`${organization_id}/product/${product}/users/${user_id}`);
        return response.data;
    } catch(error) {
        console.error("Error updating user:", error);
        throw error;
    }

}

