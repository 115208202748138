import axios, {AxiosError, InternalAxiosRequestConfig} from 'axios';
import { authStore } from '../store/authStore';

type SignOutFn = (() => Promise<void>) | undefined;

type CustomAxiosRequestConfig = InternalAxiosRequestConfig & { __retryCount?: number };

const createApiClient = (token: string, signOut?: SignOutFn, showToast?: (message: string) => void) => {
    const { resetAuthState } = authStore.getState();
    
    const client = axios.create({
        baseURL: `${import.meta.env.VITE_API_ENDPOINT}/api/user`,
        timeout: 15000,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    });

    client.interceptors.response.use(
        response => response,
        async (error: AxiosError) => {
            const { response, config } = error;
            const maxRetries = 3;
            const retryDelay = 2000;

            const customConfig = config as CustomAxiosRequestConfig;
            if (error.code === 'ECONNABORTED' && customConfig) {
                customConfig.__retryCount = customConfig.__retryCount || 0;

                if (customConfig.__retryCount < maxRetries) {
                    customConfig.__retryCount += 1;
                    console.warn(`Intento ${customConfig.__retryCount} de ${maxRetries} debido a timeout...`);
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                    return client(customConfig);
                }

                console.error("La petición tardó demasiado en responder y se agotaron los reintentos.");
                if (showToast) {
                    showToast("El servidor no responde. Intenta más tarde.");
                }
                return Promise.reject(new Error("Timeout después de varios intentos"));
            }

            if (response?.status === 401 || response?.status === 403) {
                resetAuthState();
                if (signOut) {
                    showToast?.('Vuelve a loguearte, tu token expiró.');
                    await signOut();
                } else {
                    alert('Hubo un error de autenticación');
                }
            } else if (response?.status === 400) {
                console.error("Error de validación:", response.data);
            } else {
                console.error("Error en la petición:", error.message);
            }

            return Promise.reject(error);
        }
    );

    return client;
};

const createOrganizationsApiClient = (token: string, signOut: SignOutFn) => {
    const { resetAuthState } = authStore.getState();

    const client = axios.create({
        baseURL: `${import.meta.env.VITE_API_ENDPOINT}/api/organizations`,
        timeout: 4000,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    });

    client.interceptors.response.use(
        response => response,
        async (error: AxiosError) => {
            const { response, config } = error;
            const maxRetries = 3;
            const retryDelay = 2000;

            const customConfig = config as CustomAxiosRequestConfig;
            if (error.code === 'ECONNABORTED' && customConfig) {
                customConfig.__retryCount = customConfig.__retryCount || 0;

                if (customConfig.__retryCount < maxRetries) {
                    customConfig.__retryCount += 1;
                    console.warn(`Intento ${customConfig.__retryCount} de ${maxRetries} debido a timeout...`);
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                    return client(customConfig);
                }

                console.error("La petición tardó demasiado en responder y se agotaron los reintentos.");
                return Promise.reject(new Error("Timeout después de varios intentos"));
            }

            if (response?.status === 401 || response?.status === 403) {
                resetAuthState();
                if (signOut) {
                    await signOut();
                } else {
                    alert('Hubo un error de autenticación');
                }
            } else if (response?.status === 400) {
                console.error("Error de validación:", response.data);
            } else {
                console.error("Error en la petición:", error.message);
            }

            return Promise.reject(error);
        }
    );

    return client;
};

export { createApiClient, createOrganizationsApiClient };
