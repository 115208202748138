import { Route, Routes } from "react-router-dom";
import SignInCallback from "../pages/SignInCallback.tsx";
import Products from '../pages/Products';
import Layout from "../ui/Layout";
import ProtectedRoute from "./ProtectedRoute.tsx";
import PlanAndBilling from "../pages/PlanAndBilling/index.tsx";
import Settings from '../pages/Settings';
import Welcome from '../pages/Welcome/index.tsx';
import PermissionsAndRoles from "../pages/PermissionsAndRoles/index.tsx";
import Users from "../pages/Users/index.tsx";
import OrganizationForm from "../pages/OrganizationForm/index.tsx";
import UsersForm from "../pages/UsersForm";

function AppRoutes() {

    return (
        <>
            <Routes>
                <Route path="/sign-in-callback" element={<SignInCallback />} />

                {/* Routes for users without organizationId */}
                <Route element={<ProtectedRoute restrictedOnly={true} />}>
                    <Route element={<Layout />}>
                        <Route path="/welcome" Component={Welcome} />
                        <Route path="/create-organization" Component={OrganizationForm} />
                    </Route>
                </Route>

                <Route element={<ProtectedRoute restrictedOnly={false} />}> 
                    <Route element={<Layout />}>
                        <Route path="/" Component={Products} />
                        <Route path="/plan-and-billing" Component={PlanAndBilling} />
                        <Route path="/permissions" Component={PermissionsAndRoles} />
                        <Route path="/users" Component={Users} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/users/edit/:id" element={<UsersForm />} />
                        <Route path="/edit-organization" Component={OrganizationForm} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default AppRoutes;
