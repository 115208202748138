import {createApiClient} from '../ApiClients';


export const fetchMeters = async (token: string, product: string, signOut: () => Promise<void>, showToast: (message: string) => void) => {
    try {
        const apiClient = createApiClient(token, signOut, showToast); 
        const response = await apiClient.get(`/subscriptions/${product}/meters`);

        const responseDataWithProduct = {
            ...(response.data.credits || response.data.conversations || {}),
            product: product,
        };

        return responseDataWithProduct;
    } catch (error) {
        console.error("Error fetching plans:", error);
        throw error;
    }
};
