import React from 'react';

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
}

const ElevateIcon: React.FC<IconProps> = ({ width = 24, height = 24}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1324_12811)">
            <path
                d="M20.6719 0C21.3534 0.135469 21.9558 0.335156 22.5155 0.757969C23.3489 1.3875 23.8261 2.30391 24 3.32812V13.2188C23.7586 14.145 22.5089 14.2467 22.1742 13.3336C22.0598 10.0945 22.1583 6.83344 22.1241 3.58687C22.0064 2.62547 21.2958 1.94062 20.3222 1.87312H3.63094C2.68406 1.95469 1.94813 2.68031 1.87312 3.63141V20.3691C1.94672 21.3052 2.65875 22.02 3.58641 22.1245L13.5961 22.1461C14.6039 22.3786 14.5791 23.7408 13.5938 24H3.32812C2.30109 23.8266 1.39172 23.348 0.757969 22.5155C0.334219 21.9586 0.135938 21.3511 0 20.6719V3.32812C0.2175 1.65516 1.62187 0.172969 3.32812 0H20.6719Z"
                fill="white"/>
            <path
                d="M24.0001 22.8749V23.2499C23.8782 23.6432 23.6434 23.878 23.2501 23.9999H22.8751C22.0562 23.623 21.5256 22.7437 20.8262 22.1779C20.3321 22.3766 19.9065 22.5866 19.3693 22.6738C14.7245 23.4252 12.5359 17.2119 16.5039 14.8865C20.3786 12.6158 24.5884 17.1144 22.1832 20.8363C22.7528 21.5263 23.6242 22.0612 24.0001 22.8749ZM18.3648 16.1849C15.1867 16.5444 15.9676 21.5915 19.2559 20.7791C21.9976 20.1018 21.3034 15.8526 18.3648 16.1849Z"
                fill="white"/>
            <path
                d="M10.1608 5.30859L18.445 5.29688C19.5995 5.41406 19.5995 7.05375 18.445 7.17094H10.2428C9.12484 7.0575 9.07844 5.50781 10.1608 5.30859Z"
                fill="white"/>
            <path
                d="M10.1608 10.9336L18.445 10.9219C19.5995 11.0391 19.5995 12.6787 18.445 12.7959H10.2428C9.12484 12.6825 9.07844 11.1328 10.1608 10.9336Z"
                fill="white"/>
            <path
                d="M10.1609 16.8868C10.5218 16.8339 11.7598 16.831 12.0626 16.9759C12.7507 17.3054 12.7155 18.4018 11.9993 18.6784C11.7119 18.7895 10.5869 18.7834 10.2424 18.7487C9.10382 18.6334 9.10382 17.042 10.1604 16.8864L10.1609 16.8868Z"
                fill="white"/>
            <path
                d="M5.47343 5.30896C5.79265 5.26209 6.68937 5.26021 6.97015 5.38162C7.68593 5.69146 7.66718 6.81787 6.93734 7.101C6.66593 7.206 5.82078 7.20365 5.51328 7.16662C4.43093 7.03678 4.42015 5.46412 5.47343 5.30896Z"
                fill="white"/>
            <path
                d="M5.47343 10.934C5.79265 10.8871 6.68937 10.8852 6.97015 11.0066C7.68593 11.3165 7.66718 12.4429 6.93734 12.726C6.66593 12.831 5.82078 12.8287 5.51328 12.7916C4.43093 12.6618 4.42015 11.0891 5.47343 10.934Z"
                fill="white"/>
            <path
                d="M5.47343 16.8871C5.79265 16.8402 6.68937 16.8383 6.97015 16.9597C7.68593 17.2696 7.66718 18.396 6.93734 18.6791C6.66593 18.7841 5.82078 18.7818 5.51328 18.7447C4.43093 18.6149 4.42015 17.0422 5.47343 16.8871Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1324_12811">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default ElevateIcon;
