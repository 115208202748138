import {Box, Flex, HStack, Input, InputGroup, InputRightElement, Stack, Text} from "@chakra-ui/react";
import PermissionsAndRolesTable from "../../ui/Components/PermissionsAndRolesTable";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useGetTokenOrganization} from "../../hooks/useGetAccessTokenOrganization.tsx";
import {authStore} from "../../store/authStore.tsx";
import {useLogto} from "@logto/react";
import {Button, Tabs, useCustomToast, Spinner} from "@canaia/ui-kit";
import '@canaia/ui-kit/dist/style.css';
import ElevateIcon from "../../icons/productsIcons/ElevateIcon.tsx";
import {useTranslation} from "react-i18next";
import {fetchProductUsers, updateProductUserRol} from "../../api/Calls/products.tsx";
import {SearchIcon} from "@chakra-ui/icons";
import ModalUpdateRol from "./util/modalUpdateRol.tsx";
import ModalAddUsers from "./util/modalAddUsers.tsx";
import Title from "../../ui/Components/Title";
import ProductSeats from "./util/ProductSeats.tsx";
import {User} from "./interfaces"

const products = ['assist', 'elevate']

type ProductUsers = {
    [key: string]: {
        items: User[];
        total: number;
        page: number;
        size: number;
        pages: number;
    };
};

const PermissionsAndRoles = () => {

    const {fetchUpdatedToken} = useGetTokenOrganization();
    const organizationId = authStore((state) => state.organizationId);
    const {signOut} = useLogto();
    const [productUsers, setProductUsers] = useState<ProductUsers>({});
    const [selectedTab, setSelectedTab] = useState("elevate");
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set());
    const [isModalUpdateRolOpen, setIsModalUpdateRolOpen] = useState(false);
    const [isModalUsersOpen, setIsModalUsersOpen] = useState(false);

    const { t } = useTranslation();
    const showToast = useCustomToast();
    const showToastRef = useRef(showToast);
    const productTabs = [
        {
            value: "elevate",
            label: "Elevate",
            icon: ElevateIcon,
            iconColor: "white.100",
            iconSize: "24px",
            iconBg: "elevate.500",
            iconBgRadius: "50px",
            iconPadding: "5px",
        }
    ]

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const openModal = (user:User) => {
        setSelectedUsers(new Set([user.id]));
        setIsModalUpdateRolOpen(true);
    };

    const openUsersModal = () => {
        setIsModalUsersOpen( true );
    }

    useEffect(() => {
        showToastRef.current = showToast;
    }, [showToast]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");
            if (!organizationId) throw new Error("Organization ID is missing.");

            const membersData = Object.fromEntries(
                await Promise.all(products.map(async (product) => {
                    const users = await fetchProductUsers(token, signOut, organizationId, product);
                    return [product, users];
                }))
            );

            setProductUsers(membersData);

        } catch (error) {
            console.error("Error fetching members:", error);
            showToastRef.current({
                type: "error",
                title: t("Error al obtener usuarios"),
                description: t("Inténtalo más tarde."),
                duration: 5000,
                position: "bottom-center",
            });
        } finally {
            setIsLoading(false);
        }
    }, [fetchUpdatedToken, organizationId, signOut, t]);

    useEffect(() => {
        fetchData().then();
    }, [fetchData]);

    const updateUserRole = useCallback(
        async (userId: string, newRole: string, product: string): Promise<void> => {
            try {
                const token = await fetchUpdatedToken();
                if (!token) throw new Error("No se pudo obtener el token.");
                if (!organizationId) throw new Error("Organization ID is missing.");

                await updateProductUserRol(token, signOut, organizationId, product, userId, { role: newRole });

                setProductUsers(prevState => {
                    const updatedProductUsers: ProductUsers = { ...prevState };

                    if (updatedProductUsers[product]) {
                        updatedProductUsers[product].items = updatedProductUsers[product].items.map(user =>
                            user.id === userId ? { ...user, role: newRole } : user
                        );
                    }

                    return updatedProductUsers;
                });

            } catch (error) {
                console.error("Error actualizando el rol del usuario:", error);
            }
        },
        [fetchUpdatedToken, organizationId, signOut]
    );

    const handleUsersUpdated = (updatedProductUsers: User[], product: string) => {

        setProductUsers(prevState => ({
            ...prevState,
            [product]: {
                ...prevState[product],
                items: updatedProductUsers,
            },
        }));
    };

    return (
        <Box maxW="1200px" mx={"auto"}>
            <Flex width={"100%"} alignItems={"center"}>
                <Stack spacing={4} mb={2} mr={4} flex="1" mt={2}>
                    <Title title={"Permisos y roles"}></Title>
                    <Text fontSize="lg" mb={6} color="black.500" textAlign={"left"} >
                        {t("Aquí puedes asignar usuarios a los distintos productos. ")}
                    </Text>
                </Stack>
                <ProductSeats product={selectedTab} />
            </Flex>
            <Flex width={"100%"} alignItems={"left"} mb={6}>
                <Tabs
                    selectedValue={selectedTab}
                    onChange={setSelectedTab}
                    options={productTabs}
                    tabWidth={"260px"}
                    size={"lg"}
                />
            </Flex>

            <HStack justifyContent="flex-end" alignItems="center" mb={6}>
                <Button color="primary.500"
                    bg={"primary.400"}
                    variant="solid"
                    size="lg"
                    onClick={()=>openUsersModal()}
                    text={t("Añadir usuarios")}>
                </Button>
                <Button color="primary.500"
                    bg={"primary.400"}
                    variant="solid"
                    size="lg"
                    onClick={() => setIsModalUpdateRolOpen(true)}
                    text={t("Asignación masiva de rol")}
                    disabled={selectedUsers.size === 0}
                >
                </Button>
                <InputGroup maxW="240px">
                    <InputRightElement pointerEvents="none">
                        <SearchIcon color="gray.400"/>
                    </InputRightElement>
                    <Input
                        placeholder={t("Buscar usuario...")}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        bg="white"
                        border="1px solid #E2E8F0"
                        borderRadius="md"
                        minHeight="48px"
                    />
                </InputGroup>
            </HStack>
            {isLoading ? (
                <Flex justify="center" align="center" height="200px">
                    <Spinner color="primary" />
                </Flex>
            ) : (
                <PermissionsAndRolesTable
                    users={productUsers[selectedTab]?.items || []}
                    searchTerm={searchTerm}
                    product={selectedTab}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    openModal={openModal}
                />
            )}

            {isModalUpdateRolOpen && (
                <ModalUpdateRol
                    isModalOpen={isModalUpdateRolOpen}
                    setIsModalOpen={setIsModalUpdateRolOpen}
                    selectedUsers={Array.from(selectedUsers) as string[]}
                    updateUserRole={updateUserRole}
                    product={selectedTab}
                />
            )}

            <ModalAddUsers
                users={productUsers[selectedTab]?.items || []}
                isModalOpen={isModalUsersOpen}
                setIsModalOpen={setIsModalUsersOpen}
                organizationId={organizationId}
                product={selectedTab}
                handleUsersUpdated={handleUsersUpdated}
            />

        </Box>
    )
}

export default PermissionsAndRoles;