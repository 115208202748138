
import React, {useEffect, useState, useCallback, useRef} from "react";
import {
    Box,
    Heading,
    Stack,
    Text,
    Input,
    InputGroup,
    HStack,
    FormControl,
    FormErrorMessage, InputRightElement, Flex, Spinner,
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import {useForm, Controller} from "react-hook-form";
import {t} from "i18next";
import UsersTable from "../../ui/Components/UsersTable";
import {ModalCustom, Select, Button, useCustomToast} from "@canaia/ui-kit";
import '@canaia/ui-kit/dist/style.css';
import {useGetTokenOrganization} from "../../hooks/useGetAccessTokenOrganization.tsx";
import {addMember, getMembers} from "../../api/Calls/organizations.tsx";
import axios from "axios";
import {useLogto} from "@logto/react";
import {authStore} from "../../store/authStore.tsx";
import { FiCheckCircle } from "react-icons/fi";
import {User, Response} from "../Settings/interfaces"

export interface Member {
    name: string
    last_name: string,
    phone: string,
    address: string,
    city: string,
    postal_code: string,
    country: string,
    role: string,
    email: string,
    language: string
}

const Users = () => {
    const {fetchUpdatedToken} = useGetTokenOrganization();
    const {signOut} = useLogto();
    const showToast = useCustomToast();
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [members, setMembers] = useState<Response<User> | null>(null);
    const [userAdded, setUserAdded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const organizationId = authStore((state) => state.organizationId);
    const showToastRef = useRef(showToast);

    const {handleSubmit, control, formState: {errors}, reset,} = useForm({
        defaultValues: {
            role: "",
            email: "",
            name: "",
            last_name: "",
            phone: "",
            address: "",
            city: "",
            postal_code: "",
            country: "",
            language: "es"
        },
    });

    useEffect(() => {
        showToastRef.current = showToast;
    }, [showToast]);

    const fetchMembers = useCallback(async () => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");
            if (!organizationId) throw new Error("Organization ID is missing.");
            const membersData = await getMembers(token, signOut, organizationId);
            setMembers(membersData);
        } catch (error) {
            console.error("Error fetching members:", error);
            showToastRef.current({
                type: "error",
                title: t("Error al obtener usuarios"),
                description: t("No fue posible cargar los usuarios. Inténtalo más tarde."),
                duration: 5000,
                position: "bottom-center",
            });
        }
    }, [fetchUpdatedToken, organizationId, signOut]);

    useEffect(() => {
        fetchMembers().then();
    }, [fetchMembers]);

    const onSubmitForm = async (data: Member) => {
        setIsSaving(true);
        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Error al obtener el token de acceso");
            if (!organizationId) throw new Error("Error al obtener el id de la organización.");
            await addMember(token, signOut, organizationId, {
                "name": data.name,
                "last_name": data.last_name,
                "role": data.role,
                "email": data.email
            });
            setUserAdded(true);
            await fetchMembers();
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    showToast({
                        type: "warning",
                        title: "Error de Validación",
                        description: "Hubo un problema. Por favor, verifica e inténtalo de nuevo.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else if (error.response?.status === 401 || error.response?.status === 403) {
                    showToast({
                        type: "error",
                        title: "Error de Autenticación",
                        description: "Tu sesión ha expirado.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else if (error.response?.status === 422 ) {
                    showToast({
                        type: "error",
                        title: "Error al registrar el usuario",
                        description: "Hubo un error al realizar el registro o el correo ya fue registrado",
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else {
                    showToast({
                        type: "error",
                        title: "Error",
                        description: "Hubo un problema al procesar tu solicitud. Inténtalo más tarde.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                }
            } else {
                showToast({
                    type: "error",
                    title: "Error",
                    description: "Hubo un problema al procesar tu solicitud. Inténtalo más tarde.",
                    duration: 5000,
                    position: "bottom-center",
                });
            }
        } finally {
            setIsSaving(false);
        }

    };

    const modalArgs = {
        title: t("Nuevo usuario"),
        titleAlign: 'left',
        backgroundColorModal: "white.100",
        maxWidth: !userAdded ? "600px" : "400px",
        onAcceptText: isSaving ? t("Saving...") : t("Aceptar"),
        onCancelText: !userAdded ? t("Cancelar") : t("Cerrar") ,
        onAcceptClick: handleSubmit(onSubmitForm),
        backgroundOverlayColor: 'rgba(0, 0, 0, 0.5)',
        buttonAcceptVisible: !userAdded,
        buttonCancelVisible: true,
        buttonAcceptDisable: isSaving,
        buttonCancelDisable: isSaving,
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleOpen = () => setIsModalOpen(true);

    const handleClose = () => {
        setIsModalOpen(false);
        reset();
        setUserAdded(false); 
    };

    return (
        <Box maxW="900px" mx="auto" pb={'4rem'}>
            <Stack spacing={4} mb={4}>
                <Heading as="h2" size="lg" textAlign="left">
                    {t("Usuarios")}
                </Heading>
                <Text fontSize="lg" color="gray.600" textAlign="left">
                    {t("Gestión de usuarios y roles de plataforma")}
                </Text>
            </Stack>
            <HStack justifyContent="flex-end" alignItems="center" mb={6}>
                <Button color="info.500"
                    bg={"info.500"}
                    variant="solid"
                    size="lg"
                    onClick={handleOpen}
                    text={t("Nuevo usuario")}>
                </Button>
                <InputGroup maxW="300px">
                    <InputRightElement pointerEvents="none">
                        <SearchIcon color="gray.400"/>
                    </InputRightElement>
                    <Input
                        placeholder={t("Buscar usuario...")}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        bg="white"
                        border="1px solid #E2E8F0"
                        borderRadius="md"
                        minHeight="48px"
                    />
                </InputGroup>
            </HStack>
            {members  && members.items ? (
                <UsersTable users={members.items} searchTerm={searchTerm} />
            ) : (
                <Flex justify="center" align="center" height="200px">
                    <Spinner size="xl" color="teal.500" />
                </Flex>
            )}
            <ModalCustom {...modalArgs} isOpen={isModalOpen} onClose={handleClose} onCancelClick={handleClose}>
                <Box as="form" onSubmit={handleSubmit(onSubmitForm)}>
                    {userAdded ? (
                        <Stack alignItems="center" spacing={4}>
                            <FiCheckCircle size={50} color="green" />
                            <Text fontSize="lg" color="green.600">{t("El nuevo usuario ha sido agregado exitosamente.")}</Text>
                        </Stack>
                    ) : (
                        <Stack spacing={4}>
                            <FormControl isInvalid={!!errors.name}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: t("Este campo no puede estar vacío"),
                                    }}
                                    render={({field}) => (
                                        <Input
                                            {...field}
                                            placeholder={t("Nombre")}
                                            type="text"
                                            bg="white"
                                            border="1px solid #E2E8F0"
                                            borderRadius="md"
                                        />
                                    )}
                                />
                                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.last_name}>
                                <Controller
                                    name="last_name"
                                    control={control}
                                    rules={{
                                        required: t("Este campo no puede estar vacío"),
                                    }}
                                    render={({field}) => (
                                        <Input
                                            {...field}
                                            placeholder={t("Apellido")}
                                            type="text"
                                            bg="white"
                                            border="1px solid #E2E8F0"
                                            borderRadius="md"
                                        />
                                    )}
                                />
                                <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.email}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: t("Este campo no puede estar vacío"),
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: t("Por favor ingresa un email válido"),
                                        },
                                    }}
                                    render={({field}) => (
                                        <Input
                                            {...field}
                                            placeholder={t("Email")}
                                            type="email"
                                            bg="white"
                                            border="1px solid #E2E8F0"
                                            borderRadius="md"
                                        />
                                    )}
                                />
                                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.role}>
                                <Controller
                                    name="role"
                                    control={control}
                                    rules={{
                                        required: t("Por favor selecciona un rol"),
                                    }}
                                    render={({field}) => (
                                        <Select
                                            {...field}
                                            placeholder={t("Selecciona un rol")}
                                            textSubtitle={""}
                                            options={[
                                                {name: t("Administrador"), value: "admin"},
                                                {name: t("Usuario"), value: "member"},
                                            ]}
                                        />
                                    )}
                                />
                                <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    )}
                </Box>
            </ModalCustom>
        </Box>
    );
};

export default Users;
