import React, {useState} from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { authStore } from "../../store/authStore";
import { Header, SideBar } from "@canaia/ui-kit";
import { useLogto } from "@logto/react";
import { IconRobot, IconCard, IconUsers, IconPermissons, IconDashboard } from "../../icons/sidebarIcons";
import { useTranslation } from "react-i18next";
import {fetchManagment} from "../../api/Calls/managment.tsx";
import {useGetTokenOrganization} from "../../hooks/useGetAccessTokenOrganization.tsx";

const Layout: React.FC = () => {
    const { signOut } = useLogto();
    const { organizationId, userName } = authStore.getState();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const {fetchUpdatedToken} = useGetTokenOrganization();
   
    const LogOut = () => {
        signOut(window.location.origin + "/")
    }

    const HeaderProps = {
        platform: "canaia",
        color: "primary.400",
        settingsName: t('settings'),
        showMenuItems: ['hub','play','assist','elevate'],
        onSettingsClick: () => navigate('/settings'),
        onLogOutClick: () => LogOut(),
        userName:userName || (t('loading')),
    };

    const SidebarProps = { menuOptions: [
        {
            title: (t('catalog')),
            icon: <IconRobot />,
            iconHover: <IconRobot color={"#1D79BE"}  />,
            onClick: () => navigate('/'),
            url: '/',
        },
        {
            title: (t('dashboard')),
            icon: <IconDashboard />,
            iconHover: <IconDashboard color={"#1D79BE"}  />,
            onClick: () => navigate('/dashboard'),
            url: '/dashboard',
        },
        {
            title: (t('planAndBilling')),
            icon: !loading ? <IconCard /> : <IconCard />,
            iconHover: <IconCard color={"#1D79BE"} />,
            onClick: async () => {
                try {
                    const token = await fetchUpdatedToken();
                    if (!token) throw new Error("No se pudo obtener el token.");
                    setLoading(true);
                    const product = "assist";
                    const { url } = await fetchManagment(token, product, signOut);
                    window.location.href = url;
                } catch (error) {
                    console.error("Error fetching management data:", error);
                } finally {
                    setLoading(false);
                }
            },
            url: '/plan-and-billing',
        },
        {
            title: (t('users')),
            icon: <IconUsers />,
            iconHover: <IconUsers color={"#1D79BE"}  />,
            onClick: () => navigate('/users'),
            url: '/users',
        },
        {
            title: (t('permissions')),
            icon: <IconPermissons />,
            iconHover: <IconPermissons color={"#1D79BE"}  />,
            onClick: () => navigate('/permissions'),
            url: '/permissions',
        }
    ]};
    return (
        <>
            <Header {...HeaderProps}/>
            <Flex width="100%" bg="primary.100">
                {organizationId && <SideBar {...SidebarProps} currentUrl={location.pathname} />}
                <Box flex="1" pt="130px" px="96px"   minHeight="100vh">
                    <Outlet />
                </Box>
            </Flex>

        </>
    );
};

export default Layout;
