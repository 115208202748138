import {createOrganizationsApiClient} from "../ApiClients.tsx";

interface updatedPassword {
    new_password: string;
}

interface updatedRol {
    role: string;
}

interface newMember {
    name: string;
    last_name: string;
    role: string;
    email: string;
}

interface MembersData {
    name: string,
    last_name: string,
    phone: string,
    address: string,
    city: string,
    postal_code: string,
    country: string,
    language: string
}


export const getMembers = async (token:string, signOut: () => Promise<void>, organization_id:string) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.get(`${organization_id}/members`);
        return response.data;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error;
    }

}

export const getMember = async (token:string, signOut: () => Promise<void>, organization_id:string, member_id:string) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut)
        const response = await apiClient.get(`${organization_id}/members/${member_id}`);
        return response.data;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error;
    }

}

export const updateMember = async (token:string, organization_id:string, member_id:string, data:MembersData, signOut: () => Promise<void>) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut)
        const response = await apiClient.patch(`${organization_id}/members/${member_id}`, data);
        return response.data;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error;
    }

}

export const addMember = async (token:string, signOut: () => Promise<void>, organization_id:string, data:newMember ) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.post(`${organization_id}/members`, data);
        return response.data;
    } catch (error) {
        console.error("Error creating user:", error);
        throw error;
    }

}

export const deleteMember = async (token:string, signOut: () => Promise<void>, organization_id:string, member_id:string) => {

    try{
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.delete(`${organization_id}/members/${member_id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting user:", error);
        throw error;
    }

}

export const updateMemberPassword = async (token:string, signOut: () => Promise<void>, organization_id:string, member_id:string, data: updatedPassword ) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.patch(`${organization_id}/members/${member_id}/password`, data);
        return response.data;
    }catch(error) {
        console.error("Error updating user:", error);
        throw error;
    }

}

export const updateMemberRol = async (token:string, signOut: () => Promise<void>, organization_id:string, member_id:string, data: updatedRol ) => {

    try {
        const apiClient = createOrganizationsApiClient(token, signOut);
        const response = await apiClient.patch(`${organization_id}/members/${member_id}/role`, data);
        return response.data;
    } catch(error) {
        console.error("Error updating user:", error);
        throw error;
    }

}