import {ModalCustom, Select, useCustomToast} from "@canaia/ui-kit";
import { useTranslation } from "react-i18next";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

interface ModalUpdateProps {
    setIsModalOpen: (isModalOpen: boolean) => void;
    isModalOpen: boolean;
    selectedUsers: string[];
    updateUserRole: (id: string, role: string, product: string) => Promise<void>;
    product: string;
}

const ModalUpdateRol = ({ setIsModalOpen, isModalOpen, selectedUsers, updateUserRole, product }: ModalUpdateProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const showToast = useCustomToast();

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: { role: "" }
    });

    useEffect(() => {
        setValue("role", "");
    }, [isModalOpen, setValue]);

    const handleClose = () => setIsModalOpen(false);

    const onSubmit = async (data: { role: string }) => {
        setIsLoading(true);
        try {
            await Promise.all(selectedUsers.map(userId => updateUserRole(userId, data.role, product)));
            showToast({
                type: "success",
                title: "¡Éxito!",
                description: "El rol de usuario se ha actualizado correctamente.",
                duration: 5000,
                position: "bottom-center",
            });
            handleClose();
        } catch (error) {
            console.error("Error en la actualización:", error);
            showToast({
                type: "error",
                title: "Error",
                description: "No se pudo completar la operación. Inténtalo más tarde.",
                duration: 5000,
                position: "bottom-center",
            });
        } finally {
            setIsLoading(false);
        }
    };

    let roleOptions: { name: string; value: string; }[] = [];
    if (product === 'assist' || product === 'play') {
        roleOptions = [{ name: t("Usuario"), value: "member" }];
    } else if (product === 'elevate') {
        roleOptions = [
            { name: t("Editor"), value: "editor" },
            { name: t("Viewer"), value: "viewer" }
        ];
    }

    const modalArgs = {
        backgroundColorModal: "white.100",
        title: selectedUsers.length === 1 ? "Actualizar rol" : "Asignación masiva de roles",
        titleAlign: "left",
        fontSizeTitle: "2xl",
        disableOverlay: false,
        buttonAcceptTextColor: "white.100",
        buttonAcceptBackground: "primary.400",
        buttonAcceptVisible: true,
        buttonAcceptDisable: isLoading,
        buttonCancelVisible: true,
        buttonCancelDisable: isLoading,
        backgroundOverlayColor: "rgba(0, 0, 0, 0.5)",
        buttonCancelBorder: "primary.600",
        buttonCancelBackground: "white.100",
        buttonCancelText: "primary.600",
        maxWidth: '400px',
        isCenteredModal: true,
        onAcceptText: isLoading ? t("Guardando...") : t("Asignar"),
        onCancelText: 'Cancelar',
        onAcceptClick: handleSubmit(onSubmit),
    };

    return (
        <ModalCustom {...modalArgs} isOpen={isModalOpen} onClose={handleClose} onCancelClick={handleClose}>
            <FormControl isInvalid={!!errors.role} flex="1">
                <Controller
                    name="role"
                    control={control}
                    rules={{ required: t("Por favor selecciona un rol") }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            label={"Rol"}
                            textSubtitle={""}
                            info={false}
                            placeholder={t("Selecciona un rol")}
                            options={roleOptions}
                            disableSelect={isLoading}
                        />
                    )}
                />
                <FormErrorMessage>
                    {typeof errors.role?.message === 'string' ? errors.role?.message : ''}
                </FormErrorMessage>
            </FormControl>
        </ModalCustom>
    );
};

export default ModalUpdateRol;
